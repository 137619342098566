import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { AlertCircle, Star, Package, ShieldCheck } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Modal from '@/components/Modals/Modal';
import { Button } from '@/components/ui/button';

const BaseMembershipUpgradeModal = NiceModal.create(() => {
  const modal = useModal('base-membership-upgrade-modal');
  const navigate = useNavigate();

  const handleUpgrade = () => {
    navigate('/memberships');
    modal.hide();
  };

  return (
    <Modal modal={modal} title="Save Money with Our Membership Plans!">
      <div className="flex flex-col justify-center items-center gap-4 p-4 pb-0">
        <div className="flex justify-center mb-4">
          <AlertCircle className="w-16 h-16 text-brand-purple" />
        </div>

        <div className="text-2xl font-semibold text-center">Thinking About Saving Money?</div>
        <div className="text-center text-gray-700">
          If you're selecting more than 16 pharmacies, upgrading to a membership can save you money and unlock more
          benefits!
        </div>

        <div className="flex flex-col gap-4 mx-2 mt-4">
          <div className="p-4 border rounded-lg shadow-sm">
            <div className="text-lg font-semibold text-center">Base Membership</div>
            <div className="text-center text-gray-600">
              Perfect for moderate usage—only <span className="font-semibold">$16/month</span>. Includes:
            </div>
            <ul className="mt-2 text-gray-600 list-disc list-inside">
              <li>20 call credits per month.</li>
              <li>Manage multiple medications effortlessly.</li>
            </ul>
          </div>

          <div className="p-4 border rounded-lg shadow-sm bg-gray-50">
            <div className="text-lg font-semibold text-center text-brand-purple">Unlimited Membership</div>
            <div className="text-center text-gray-600">
              For just <span className="font-semibold">$59/month</span>, unlock these premium benefits:
            </div>
            <div className="mt-2 flex flex-col gap-2 text-gray-600">
              <div className="flex items-center gap-2">
                <Star className="w-5 h-5 text-brand-purple" />
                <span>Unlimited call credits—no limits, no worries.</span>
              </div>
              <div className="flex items-center gap-2">
                <ShieldCheck className="w-5 h-5 text-brand-purple" />
                <span>
                  Our <span className="font-semibold">guarantee to find your medications</span>, giving you peace of
                  mind.
                </span>
              </div>
              <div className="flex items-center gap-2">
                <Package className="w-5 h-5 text-brand-purple" />
                <span>Priority support for all your needs.</span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-3 mt-6">
          <Button onClick={handleUpgrade}>View Membership Plans</Button>
          <Button
            variant="outline"
            onClick={() => {
              modal.resolve(false);
              modal.hide();
            }}
          >
            Continue Search
          </Button>
        </div>
      </div>
    </Modal>
  );
});

export default BaseMembershipUpgradeModal;
