import NiceModal from '@ebay/nice-modal-react';
import LocationModal from '@/components/Location/LocationInput.tsx';
import MedicationModal from '@/components/Modals/Medications/MedicationModal.tsx';
import TaskTransferModal from '../Requests/TaskTransferModal';
import MedicationResultsModal from './MedicationResults/MedicationResultsModal';
import BaseMembershipUpgradeModal from './Memberships/BaseMembershipModal';
import MembershipModal from './Memberships/MembershipModal';
import UnlimitedMembershipUpgradeModal from './Memberships/UnlimitedMembershipModal';
import PharmacyLimitModal from './Pharmacy/PharmacyLimitModal';

NiceModal.register('medication-modal', MedicationModal);
NiceModal.register('location-modal', LocationModal);
NiceModal.register('medication-results-modal', MedicationResultsModal);
NiceModal.register('membership-modal', MembershipModal);
NiceModal.register('task-transfer-modal', TaskTransferModal);
NiceModal.register('pharmacy-limit-modal', PharmacyLimitModal);
NiceModal.register('base-membership-upgrade-modal', BaseMembershipUpgradeModal);
NiceModal.register('unlimited-membership-upgrade-modal', UnlimitedMembershipUpgradeModal);

const ModalRegistration = () => {
  return <></>;
};

export default ModalRegistration;
