import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { AlertCircle, PhoneOutgoing, Building2, Mail } from 'lucide-react';
import Modal from '@/components/Modals/Modal';
import { Button } from '@/components/ui/button';

const PharmacyLimitModal = NiceModal.create(() => {
  const modal = useModal('pharmacy-limit-modal');

  return (
    <Modal modal={modal} title="Pharmacy Selection Notice">
      <div className="flex flex-col justify-center items-center gap-2 p-4 pb-0">
        <div className="flex justify-center mb-4">
          <AlertCircle className="w-16 h-16 text-brand-purple" />
        </div>

        <div className="text-2xl font-semibold text-center">Pharmacy Selection Limit Reached</div>
        <div className="text-center text-gray-700">
          To get started, we’ll process the first 50 pharmacies in your selection. If additional pharmacies are needed
          to locate your medication, our team will follow up with you directly.
        </div>

        <div className="flex flex-col gap-3 mx-2 mt-3 mb-12">
          <div className="flex flex-row items-center gap-3">
            <Building2 className="w-6 h-6" />
            <div className="text-gray-600">The first 50 pharmacies will be prioritized immediately.</div>
          </div>
          <div className="flex flex-row items-center gap-3">
            <PhoneOutgoing className="w-6 h-6" />
            <div className="text-gray-600">Our team will reach out for any additional support.</div>
          </div>
          <div className="flex flex-row items-center gap-3">
            <Mail className="w-6 h-6" />
            <div className="text-gray-600">We’re here to assist with larger or special requests.</div>
          </div>
        </div>

        <div className="flex flex-col gap-3">
          <Button
            onClick={() => {
              modal.resolve(true);
              modal.hide();
            }}
          >
            Proceed with the First 50 Pharmacies
          </Button>
          <Button
            variant="outline"
            onClick={() => {
              modal.resolve(false);
              modal.hide();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
});

export default PharmacyLimitModal;
