import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { AlertCircle, Infinity, ShieldCheck, UserPlus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Modal from '@/components/Modals/Modal';
import { Button } from '@/components/ui/button';

const UnlimitedMembershipUpgradeModal = NiceModal.create(() => {
  const modal = useModal('unlimited-membership-upgrade-modal');
  const navigate = useNavigate();

  const handleUpgrade = () => {
    navigate('/memberships');
    modal.hide();
  };

  return (
    <Modal modal={modal} title="Go Unlimited and Save Big!">
      <div className="flex flex-col justify-center items-center gap-4 p-4 pb-0">
        <div className="flex justify-center mb-4">
          <AlertCircle className="w-16 h-16 text-brand-purple" />
        </div>

        <div className="text-2xl font-semibold text-center">Upgrade to Unlimited Membership?</div>
        <div className="text-center text-gray-700">
          If you're selecting more than 60 pharmacies, our <span className="font-semibold">Unlimited Membership</span>{' '}
          is perfect for you. For just <span className="font-semibold">$59/month</span>, enjoy unlimited searches and
          premium perks!
        </div>

        <div className="flex flex-col gap-4 mx-4 mt-5 mb-6">
          <div className="p-3 border rounded-lg shadow-sm bg-gray-50 flex items-center gap-3">
            <Infinity className="w-6 h-6 text-brand-purple" />
            <div className="text-gray-600">
              <span className="font-semibold">Unlimited searches</span>—we'll find your prescription in stock,
              guaranteed.
            </div>
          </div>
          <div className="p-3 border rounded-lg shadow-sm bg-gray-50 flex items-center gap-3">
            <ShieldCheck className="w-6 h-6 text-brand-purple" />
            <div className="text-gray-600">
              <span className="font-semibold">Refunds</span> for any failed searches—your satisfaction is our priority.
            </div>
          </div>
          <div className="p-3 border rounded-lg shadow-sm bg-gray-50 flex items-center gap-3">
            <UserPlus className="w-6 h-6 text-brand-purple" />
            <div className="text-gray-600">
              <span className="font-semibold">Priority support</span> with access to a dedicated service line.
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-3">
          <Button onClick={handleUpgrade}>Go to Membership Plans</Button>
          <Button
            variant="outline"
            onClick={() => {
              modal.resolve(false);
              modal.hide();
            }}
          >
            Continue Search
          </Button>
        </div>
      </div>
    </Modal>
  );
});

export default UnlimitedMembershipUpgradeModal;
